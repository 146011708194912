/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-annotations-tab,
.neuroglancer-annotation-layer-view,
.neuroglancer-tags-tab {
  display: flex;
  align-items: stretch;
  flex: 1;
  flex-direction: column;
}

.neuroglancer-annotation-list {
  position: relative;
  margin: 0px;
  padding: 0px;
  margin-top: 2px;
  overflow-y: auto;
  height: 0px;
  flex: 1;
  flex-basis: 0px;
  min-height: 0px;
}

.neuroglancer-annotation-list-entry {
  display: grid;
  grid-auto-rows: min-content;
  cursor: pointer;
  justify-content: start;
}

.neuroglancer-annotation-position {
  display: contents;
}

.neuroglancer-annotation-list-header {
  grid-auto-rows: min-content;
  display: grid;
  padding-bottom: 2px;
  justify-content: start;
}

.neuroglancer-annotation-coordinate {
  font-family: monospace;
  text-align: right;
}

.neuroglancer-annotation-icon {
  grid-column: symbol;
  padding-right: 5px;
}

.neuroglancer-annotation-description,
.neuroglancer-annotation-tags {
  grid-column: dim / -1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.neuroglancer-annotation-list-entry-delete {
  grid-column: delete;
  margin-left: 1ch;
  align-self: start;
  visibility: hidden;
}

.neuroglancer-annotation-list-entry:hover
  > .neuroglancer-annotation-list-entry-delete {
  visibility: visible;
}

.neuroglancer-annotation-hover {
  background-color: #333;
}

.neuroglancer-annotation-selected {
  background-color: #939;
}

.neuroglancer-annotation-hover.neuroglancer-annotation-selected {
  background-color: #969;
}

.neuroglancer-tab-content.neuroglancer-annotation-details {
  flex: 0 0 auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.neuroglancer-annotation-details-title {
  display: flex;
  flex-direction: row;
  background-color: #03c;
  align-self: stretch;
  padding: 2px;
}

.neuroglancer-voxel-coordinates-link {
  cursor: pointer;
}

.neuroglancer-voxel-coordinates-link:hover {
  background-color: #db4437;
}

.neuroglancer-annotation-details-icon {
  display: inline-block;
  margin-right: 5px;
}

.neuroglancer-annotation-details-title-text {
  display: inline-block;
  flex: 1;
}

textarea.neuroglancer-annotation-details-description {
  align-self: stretch;
  background-color: #222;
  color: white;
  font: 10pt sans-serif;
  border: 0px;
  outline: 0px;
  resize: none;
  overflow-y: scroll;
}

div.neuroglancer-annotation-details-description {
  align-self: stretch;
  color: white;
  font: 10pt sans-serif;
}

.neuroglancer-annotation-details-position {
}

.neuroglancer-annotation-toolbox {
  display: flex;
  align-items: stretch;
}

.neuroglancer-annotation-segment-list {
}

.neuroglancer-annotation-segment-item {
  color: black;
  background-color: white;
}

.neuroglancer-annotations-view-dimension {
  font-family: monospace;
  text-align: right;
}

.neuroglancer-annotations-view-dimension-name {
  color: #ff6;
}

.neuroglancer-annotations-view-dimension-scale {
  color: #bbb;
}

.neuroglancer-annotations-view-dimension-scale:not(:empty)::before {
  content: "(";
  margin-left: 1ch;
}

.neuroglancer-annotations-view-dimension-scale:not(:empty)::after {
  content: ")";
}

.neuroglancer-annotation-relationship-label,
.neuroglancer-annotation-property-label {
  margin-right: 5px;
}

.neuroglancer-selected-annotation-details-position-grid {
  display: grid;
  grid-auto-rows: auto;
  font-family: monospace;
  font-size: medium;
  grid-auto-flow: dense;
}

.neuroglancer-selected-annotation-details-icon {
  grid-rows: 1 / -1;
  grid-column: icon;
  align-self: start;
}

.neuroglancer-selected-annotation-details-delete {
  grid-rows: 1 / -1;
  grid-column: delete;
  align-self: start;
}

.neuroglancer-selected-annotation-details-position-dim {
  color: #ff6;
  margin-left: 1ch;
}

.neuroglancer-selected-annotation-details-position-coord {
  text-align: right;
  margin-left: 0.5ch;
}

.neuroglancer-related-segment-list-title {
  font-family: sans-serif;
  font-size: small;
  background-color: #333;
}

.neuroglancer-related-segment-list-header {
  display: flex;
  flex-direction: row;
}

.neuroglancer-related-segment-list {
  /*  border: 1px solid #333;
  margin: 3px;*/
}

.neuroglancer-annotation-property {
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  font-size: small;
  user-select: text;
}

.neuroglancer-annotation-property-label {
  color: #999;
}

.neuroglancer-annotation-property-value {
  text-align: right;
  font-family: monospace;
  font-size: medium;
  flex: 1;
}

input.neuroglancer-segment-list-entry-id {
  outline: 0px;
  border: 0px;
  text-align: left;
}

.neuroglancer-segment-list-entry-new > .neuroglancer-segment-list-entry-copy {
  visibility: hidden;
}

.neuroglancer-segment-list-entry-new > input[type="checkbox"] {
  visibility: hidden;
}

.neuroglancer-selection-annotation-status {
  font-family: sans-serif;
  font-size: small;
}
